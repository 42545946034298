<template>
  <TPop :visible.sync="isShow" :show-close="false" @close="close">
    <div class="box" >
      <aspectRatio aspect="402/291" class="img-box" v-loading="loading">
        <div class="red-rain-img" :class="{'red-rain-img-back':isBack}" @click="receive">
          <img src="@/assets/images/red-rain-card.png" class="bg-img">
        </div>
        <div class="red-rain-img" :class="{'red-rain-img-back':!isBack}">
          <img src="@/assets/images/red-rain-card2.png" class="bg-img">
          <img src="@/assets/images/red-rain-card-bg.png" class="bg-img-light">
          <div class="price" :data-text="`${rainData.currency} ${numFormat(price)}`">{{ rainData.currency }} {{ numFormat(price) }}</div>
        </div>
      </aspectRatio>
      <div class="tip" v-if="!isBack">{{ $t("点击领取奖励") }}</div>
      <div class="tip" v-else>
        <span class="el-icon el-icon-circle-close" @click="isShow = false"></span>
      </div>
    </div>
  </TPop>
</template>

<script>
import aspectRatio from '../aspectRatio.vue';
import TPop from "../TPop.vue";
import {red_packet_receive_api} from '@/api/redPacket';
export default {
  data() {
    return {
      isShow: false,
      isBack: false,
      price: 0,
      loading:false
    };
  },
  components: { TPop , aspectRatio },
  props:{
    rainData:{
      default:null
    }
  },
  methods: {
    show(){
      this.isShow = true
      this.isBack = false
    },
    close(){
      this.$parent.$refs.redEnvelopeRainAnimate.hide()
    },
    async receive(){
      this.loading = true
      const {data:res} = await red_packet_receive_api({rid:this.rainData.now_data.rid})
      this.loading = false
      if(res.code===1){
        this.isBack = true
        this.price = res.data
        this.updateUserInfo()
        this.$emit("receive")
      }else{
        this.isShow = false
        this.errorTips(res.msg);
      }
    }
  },
};
</script>

<style scoped lang="less" src="@/assets/css/redEnvelopeRainSuecces.less"></style>