<template>
  <moveComponents class="red-rain-icon-box"  v-if="$route.path==='/'" :magnetic="true" :limitPosition="{left:20,right:20,bottom:90,top:140}">
    <div @click="showRain">
      <img src="@/assets/images/red-rain-icon.png" alt="">
    </div>
  </moveComponents>
</template>

<script>
import moveComponents from "@/components/moveComponent.vue"
export default {
  components:{moveComponents},
  methods:{
    showRain(){
      if(this.$store.state.isLoginStatu){
        this.$parent.showPop()
      }else{
        this.$parent.$refs.redEnvelopeRainNone.show()
      }
    }
  }
}
</script>

<style scoped lang="less">
.red-rain-icon-box{
  width: 50px;
  height: 50px;
  position: fixed;
  cursor: pointer;
  user-select: none;
  z-index: 99;
  @media (min-width: 769px) {
    bottom: 230px !important;
    right: 30px !important;
  }
  @media (max-width: 768px) {
    right: 20px;
    bottom: 210px;
    bottom: calc(210px + constant(safe-area-inset-bottom));
    bottom: calc(210px + env(safe-area-inset-bottom));
  }
}
</style>