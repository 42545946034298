<template>
  <div class="aspect-ratio-box" :style="`padding-bottom:${runStr(aspect)}`" @click="$emit('click')">
    <div class="aspect-ratio-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props:{
    aspect:{
      type:String,
      default:"1/1"
    }
  },
  methods:{
    runStr(str){
      return eval(str) * 100 + "%"
    }
  }
}
</script>

<style scoped lang="less">
.aspect-ratio-box{
  position: relative;
  padding-bottom: 100%;
}
.aspect-ratio-content{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>