<template>
  <TPop :visible.sync="isShow">
    <div class="box" @click="showSuecces">
      <div
        class="img-box"
        v-for="(item, index) in imgList"
        :key="index"
        :style="`z-index:${Math.floor(item.scale * 100)};
        left:${item.left}%;
        top:calc(${item.top}% - var(--width));
        animation-delay:${item.delay}s;
        rotate:${item.rotate}deg;
        --scale:${item.scale};
        --duration:${5 / item.scale}s`"
      >
        <img src="@/assets/images/redEnvelopeRainCard.png" alt="" />
      </div>
      <img src="@/assets/images/redEnvelopeRainFoot.png" class="foot-img" />
      <img src="@/assets/images/redEnvelopeRainLeft.webp" class="left-img" />
      <img src="@/assets/images/redEnvelopeRainRight.webp" class="right-img" />
    </div>
  </TPop>
</template>

<script>
import TPop from "../TPop.vue";
export default {
  components: { TPop },
  data() {
    return {
      isShow: false,
      imgList: [],
    };
  },
  methods: {
    show(){
      this.isShow = true
    },
    hide(){
      this.isShow = false
    },
    showSuecces(){
      if(this.$store.state.isLoginStatu){
        this.$parent.$refs.redEnvelopeRainSuecces.show()
      }else{
        this.isShow = false
        this.$router.push('?modal=LoginRegistration&data=tab-0')
      }
    },
    initImgList() {
      for (let i = 0; i < 100; i++) {
        this.imgList.push({
          left: Math.random() * 150,
          top: Math.random() * 5 - 20,
          delay: Math.random() * 5,
          rotate: Math.random() * 10 + 10,
          scale: Math.random() * 0.7 + 0.3,
          imgRotate: Math.random() * 360,
        });
      }
    },
  },
  created() {
    this.initImgList();
  },
};
</script>

<style
  scoped
  lang="less"
  src="@/assets/css/redEnvelopeRainAnimate.less"
></style>
