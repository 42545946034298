// task 任务 sign 签到， action 活动，pdd PDD转盘， invite 邀请，vip_week VIP周礼金，vip_month VIP月礼金
export default {
  namespaced: true,
  state:{
    noMsg:{
      task:0,
      sign:0,
      action:0,
      pdd:0,
      invite:0,
      vip_week:0,
      vip_month:0,
    }
  },
  mutations:{
    updateNoMsg(state,type,num){
      state.noMsg[type] = num
    },
    reduce(state,type){
      if(state.noMsg[type] > 0 ){
        state.noMsg[type]--
      }
    }
  },
  getters:{
    allNoMsg(state){
      let allNoMsg = 0
      for (const key in state.noMsg) {
        allNoMsg += state.noMsg[key]
      }
      return allNoMsg
    }
  }
}

