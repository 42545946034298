import { index_menu_list_api } from "@/api"

export default {
  namespaced: true,
  state: {
    menuList: {}
  },
  mutations: {
    updateFiveBessData(state, data) {
      state.menuList = data
    }
  },
  actions: {
    async getData({ commit }) {
      const storageData = localStorage.getItem("menulist")
      if (storageData) {
        commit("updateFiveBessData", JSON.parse(storageData))
      }
      const { data: res } = await index_menu_list_api()
      if (res.code == 1) {
        commit("updateFiveBessData", res.data)
        localStorage.setItem("menulist", JSON.stringify(res.data))
      }
    }
  }
}