// Pixel埋点
class Okspin {
  constructor(key) {
    this.key = key
    this.init(key)
  }

  isPixel() {
    return this.key
  }
  // 完成注册
  register() {
    if (this.isPixel()) {
      window._okTag = window._okTag || []
      window._okTag.push({
        "eid": "register"
      })
    }
  }

  init(key) {
    if (this.isPixel()) {
      const script = document.createElement("script")
      script.src = `https://s.oksp.in/js/tag.js?aa=${key}`
      script.onload = () => {
        window._okTag = window._okTag || []
        window._okTag.push({
          "eid": "homepage",
        })
      }
      document.querySelector("head").appendChild(script)


    }
  }

  // 监听首冲完成
  initialCharge(price, currency) {
    if (this.isPixel()) {
      if (this.isPixel()) {
        window._okTag = window._okTag || []
        window._okTag.push({
          "eid": "purchase",
          "data": {
            "items": {
              price,
              productId: currency,
              quantity: "1"
            },
            "transactionId": String(Math.floor(Math.random * 1000000000000))
          }
        })
      }
    }
  }
}
export default Okspin;