<template>
  <div v-if="rainData.now_data.rid">
    <redEnvelopeRainAnimate ref="redEnvelopeRainAnimate" :rainData="rainData"/>
    <redEnvelopeRainNone ref="redEnvelopeRainNone" :rainData="rainData"/>
    <redEnvelopeRainPageFloatIcon ref="redEnvelopeRainPageFloatIcon"/>
    <redEnvelopeRainPop ref="redEnvelopeRainPop" :rainData="rainData"/>
    <redEnvelopeRainSuecces ref="redEnvelopeRainSuecces" :rainData="rainData" @receive="getData"/>
  </div>
</template>

<script>
import redEnvelopeRainAnimate from "./redEnvelopeRainAnimate";
import redEnvelopeRainNone from "./redEnvelopeRainNone";
import redEnvelopeRainPageFloatIcon from "./redEnvelopeRainPageFloatIcon";
import redEnvelopeRainPop from "./redEnvelopeRainPop";
import redEnvelopeRainSuecces from "./redEnvelopeRainSuecces";
import {red_packet_list_api} from '@/api/redPacket'
export default {
  components:{
    redEnvelopeRainAnimate,
    redEnvelopeRainNone,
    redEnvelopeRainPageFloatIcon,
    redEnvelopeRainPop,
    redEnvelopeRainSuecces,
  },
  data(){
    return {
      rainData:{
        now_data:{},
        now_day:[],
        now_day_str:"",
        next_day_str:"",
        next_day:[],
      },
      timeId:null,
      isFirstAnimatePop:false
    }
  },
  mounted(){
    this.init()
  },
  methods:{

    // 初始化
    async init(){
      this.timeId && clearInterval(this.timeId)
      this.isFirstAnimatePop = false
      await this.getData()
      if(!this.rainData.now_data.rid) return
      // 定时验证时间到没有开始时间和结束时间
      if(this.$t("red_envelope_rain_auto_join") === "red_envelope_rain_auto_join" || this.$t("red_envelope_rain_auto_join")==="open" ){
        this.testStart()
        this.timeId = setInterval(()=>{
          if(!this.isFirstAnimatePop){
            this.testStart()
          }
          this.testEndTIme();
        },3000)
      }
    },

    // 判断是否开始下红包雨，在时段内并且状态不等于0，都可以下红包雨
    testStart(){
      if(this.rainData.now_data.sort - new Date().getTime()/1000 <= 0 
      && this.rainData.now_data.end_time - new Date().getTime()/1000 > 0
      && this.rainData.now_data.join !== 0){
        this.isFirstAnimatePop = true
        this.$refs.redEnvelopeRainAnimate.show()
        return true
      }
    },

    // 活动结束,进行刷新数据,下次活动开始
    testEndTIme(){
      // 判断是够结束，活动时间结束了重新刷新数据，初始化下一次并进行倒计时
      if(this.rainData.now_data.end_time - new Date().getTime()/1000 < 0 ){
        this.init();
      }
    },
    // 获取数据
    async getData(){
      const {data:res} = await red_packet_list_api()
      if(res.code === 1){
        this.rainData = res.data
      }
    },

    // 显示弹窗
    async showPop(){
      if(!this.testStart()){
        if(this.$store.state.isLoginStatu){
          this.$refs.redEnvelopeRainPop.show()
        }else{
          this.$refs.redEnvelopeRainNone.show()
        }
      }
    }
  }
}
</script>