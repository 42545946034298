<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :modal-append-to-body="false"
    width="auto"
    :destroy-on-close="destroyOnClose"
  >
    <div class="box">
      <slot />
    </div>
  </el-dialog>
</template>

<script>
export default {
  props:{
    "destroyOnClose":{
      default:true
    }
  }
};
</script>

<style scoped lang="less">
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  color: #fff;
  padding: 0;
}
::v-deep .el-dialog__headerbtn {
  right: 14px;
  top: 14px;
}
::v-deep .el-dialog {
  box-shadow: none;
  margin: 0 !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #1c3b2f;
  border-radius: 10px;
  overflow: hidden;
}
</style>
